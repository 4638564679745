// login
export const LOGIN_ADMIN = "LOGIN_ADMIN";
export const LOGOUT_ADMIN = "LOGOUT_ADMIN";
export const SIGNUP_ADMIN = "SIGNUP_ADMIN";
export const SET_ADMIN = "SET_ADMIN";
export const UNSET_ADMIN = "UNSET_ADMIN";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const SEND_EMAIL = "SEND_EMAIL";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const ADMIN_EARNING = "ADMIN_EARNING";
export const COIN_PLAN_EARNING = "COIN_PLAN_EARNING";
